import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { IconSystemV2ColoredCourseColorQuestionAnswer } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredCourseColorQuestionAnswer';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';

const AboutSuperLMS = styled.div`
  width: 55%;
  position: relative;
  scroll-margin: 80px;
  padding: 0 26px;
  ${toScreen(1199)} {
    width: 60%;
    padding: 0;
  }
  ${toScreen(767)} {
    width: 100%;
    br {
      display: none;
    }
  }
`;

const AboutSuperLMSLine = styled.div`
  position: absolute;
  border: 6px solid ${colorsV2.grey20};
  border-radius: 10px;
  height: 99%;
  top: 4px;
  ${toScreen(1366)} {
    left: 13px;
    border: 2px solid ${colorsV2.grey20};
    border-radius: 0;
    height: 100%;
    top: 0;
  }
  ${toScreen(1199)} {
    left: 0;
    border: 2px solid ${colorsV2.grey20};
    border-radius: 0;
    height: 100%;
    top: 0;
  }
  ${toScreen(767)} {
    display: none;
  }
`;
const AboutSuperLMSTitleContainer = styled.div`
  padding-bottom: 20px;
  padding-left: 32px;
  display: flex;
  ${flexGap([16, 16])}
  ${toScreen(767)} {
    padding: 0 24px 12px;
    flex-direction: column;
  }
`;

const AboutSuperLMSContentContainer = styled.div`
  padding-left: 32px;
  .lp-about-preline {
    white-space: pre-wrap;
  }
  ${toScreen(767)} {
    width: 100%;
    padding: 0 24px;
  }
`;

export const AboutDolSuperLMS = React.forwardRef((props, ref?: React.Ref<HTMLDivElement>) => {
  return (
    <AboutSuperLMS ref={ref}>
      <AboutSuperLMSLine></AboutSuperLMSLine>
      <AboutSuperLMSTitleContainer>
        <IconSystemV2ColoredCourseColorQuestionAnswer size={28} />
        <ResponsiveTypography.Title level={1} variant="bold/24-32" color="grey180">
          Đôi lời về DOL superLMS
        </ResponsiveTypography.Title>
      </AboutSuperLMSTitleContainer>
      <AboutSuperLMSContentContainer>
        <ResponsiveTypography.Text variant="regular/16-28" color="gray140">
          DOL superLMS mang đến một trải nghiệm học toàn diện. giúp bạn tiếp cận việc học tiếng Anh
          một cách dẽ dàng, và tiết kiệm thời gian hơn.&nbsp;
          <br></br>
        </ResponsiveTypography.Text>
        <ResponsiveTypography.Text variant="SemiBold/16-28" color="grey180">
          DOL superLMS đã được phát triển trong 5 năm,&nbsp;
        </ResponsiveTypography.Text>
        <ResponsiveTypography.Text variant="regular/16-28" color="gray140">
          đi qua rất nhiều lần đập đi xây lại, và biết bao thời gian, tâm trí, trăn trở của cả một
          tập thể hơn 200 con người.&nbsp;
        </ResponsiveTypography.Text>
        <ResponsiveTypography.Text variant="SemiBold/16-28" color="grey180">
          Nay, DOL xin dành riêng tình cảm nhỏ bé này, chỉ riêng cho các bạn học viên đã & đang theo
          học tại DOL IELTS Đình Lực.
        </ResponsiveTypography.Text>
      </AboutSuperLMSContentContainer>
    </AboutSuperLMS>
  );
});
