import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g clipPath="url(#IconSystemV2ColoredCourseColorQuestionAnswer_svg__IconSystemV2ColoredCourseColorQuestionAnswer_svg__clip0_7160_60751)"><path fill="url(#IconSystemV2ColoredCourseColorQuestionAnswer_svg__IconSystemV2ColoredCourseColorQuestionAnswer_svg__paint0_linear_7160_60751)" d="M2 4v11.793a.5.5 0 00.854.353l3-3A.5.5 0 016.207 13H15a2 2 0 002-2V4a2 2 0 00-2-2H4a2 2 0 00-2 2z" /><path fill="url(#IconSystemV2ColoredCourseColorQuestionAnswer_svg__IconSystemV2ColoredCourseColorQuestionAnswer_svg__paint1_linear_7160_60751)" d="M22 20.887V8a2 2 0 00-2-2h-.5a.5.5 0 00-.5.5V13a2 2 0 01-2 2H6.5a.5.5 0 00-.5.5v.5a2 2 0 002 2h9.5l3.668 3.26a.5.5 0 00.832-.373z" /></g><defs><linearGradient id="IconSystemV2ColoredCourseColorQuestionAnswer_svg__IconSystemV2ColoredCourseColorQuestionAnswer_svg__paint0_linear_7160_60751" x1={15.01} x2={7.07} y1={15.69} y2={2.28} gradientUnits="userSpaceOnUse"><stop stopColor="#D14242" /><stop offset={1} stopColor="#F18989" /></linearGradient><linearGradient id="IconSystemV2ColoredCourseColorQuestionAnswer_svg__IconSystemV2ColoredCourseColorQuestionAnswer_svg__paint1_linear_7160_60751" x1={19.88} x2={11.41} y1={20.61} y2={6.3} gradientUnits="userSpaceOnUse"><stop stopColor="#D14242" /><stop offset={1} stopColor="#F18989" /></linearGradient><clipPath id="IconSystemV2ColoredCourseColorQuestionAnswer_svg__IconSystemV2ColoredCourseColorQuestionAnswer_svg__clip0_7160_60751"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath></defs></svg>;
});
export const IconSystemV2ColoredCourseColorQuestionAnswer = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));