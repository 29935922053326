import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<g fill="currentColor" clipPath="url(#IconSystemV2CourseTestMarkTestGrammar_svg__IconSystemV2CourseTestMarkTestGrammar_svg__clip0_12515_140046)"><path d="M6 15.923C6 17.07 6.93 18 8.077 18H13.5a1 1 0 100-2H8.077A.077.077 0 018 15.923V4.077C8 4.034 8.034 4 8.077 4h11.846c.043 0 .077.034.077.077V11.5a1 1 0 102 0V4.077C22 2.93 21.07 2 19.923 2H8.077C6.93 2 6 2.93 6 4.077v11.846z" /><path d="M3 6a1 1 0 011 1v12.958a.182.182 0 00.044.044H11.5a1 1 0 110 2H3.99c-.573-.002-1.062-.282-1.387-.609C2.277 21.066 2 20.575 2 20V7a1 1 0 011-1zm11.513.33a1 1 0 01-.074 1.413L12.59 9.41a1 1 0 01-1.339 0l-.92-.829a1 1 0 011.34-1.486l.25.226L13.1 6.257a1 1 0 011.412.074zM11 14a1 1 0 110-2 1 1 0 010 2zm6-2h-2.5a1 1 0 100 2H17a1 1 0 100-2z" /><path fillRule="evenodd" d="M21.863 15.577a1.967 1.967 0 00-2.784 0l-3.503 3.503c-.37.37-.576.87-.576 1.391v2.545c0 .543.44.984.984.984h2.545c.521 0 1.022-.207 1.391-.576l3.503-3.504a1.968 1.968 0 000-2.784l-1.56-1.56zm-1.392 1.391l1.56 1.56-3.502 3.504h-1.56v-1.56l3.502-3.504z" clipRule="evenodd" /></g><defs><clipPath id="IconSystemV2CourseTestMarkTestGrammar_svg__IconSystemV2CourseTestMarkTestGrammar_svg__clip0_12515_140046"><path fill="currentColor" d="M0 0h24v24H0z" /></clipPath></defs></svg>;
});
export const IconSystemV2CourseTestMarkTestGrammar = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));