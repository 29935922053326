import { IMenuItem } from './types';

export const menuItems: IMenuItem[] = [
  {
    value: 'gioi_thieu',
    label: 'Giới Thiệu',
  },
  {
    value: 'ly_do_phat_trien',
    label: 'Lý do phát triển',
  },
  {
    value: 'tinh_nang',
    label: 'Tính năng',
  },
  {
    value: 'gia_tri_mang_lai',
    label: 'Giá trị mang lại',
  },
];
