import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { IconSystemV2SpecificAi5 } from '@doltech/icons/SystemV2/IconSystemV2SpecificAi5';
import { ListFeature } from './ListFeature';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
const FeatureWrapper = styled.div`
  padding: 140px 0 40px;
  ${toScreen(767)} {
    padding: 104px 0 32px;
  }
`;
const FeatureBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  scroll-margin: 80px;
`;

const FeatureBoxBorder = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colorsV2.grey40};
  border-radius: 20px;
  padding: 6px 12px;
  box-shadow: ${shadows.neutralShadowToBot3};
  .lp-connect-text {
    padding-left: 6px;
  }
`;

const FeatureTitleContainer = styled.div`
  padding-bottom: 16px;
  max-width: 555px;
`;

export const Feature = React.forwardRef((props, ref?: React.Ref<HTMLDivElement>) => {
  return (
    <>
      <FeatureWrapper>
        <FeatureBox ref={ref}>
          <FeatureBoxBorder>
            <IconSystemV2SpecificAi5 size={20} color={colorsV2.primary100} />
            <ResponsiveTypography.Paragraph
              className="lp-connect-text"
              variant="regular/14-20"
              color="grey180"
            >
              Tính năng
            </ResponsiveTypography.Paragraph>
          </FeatureBoxBorder>
        </FeatureBox>
        <FeatureTitleContainer>
          <ResponsiveTypography.Title
            level={1}
            variant={['bold/28-36', 'bold/32-40', 'bold/40-48']}
            breakpoints={[0, 768, 960]}
            color="grey180"
          >
            Đầy đủ tính năng với nội dung chuẩn, giao diện đẹp
          </ResponsiveTypography.Title>
        </FeatureTitleContainer>
      </FeatureWrapper>
      <ListFeature />
    </>
  );
});
