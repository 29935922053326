import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { IconSystemV2EssentialEyeFilled } from '@doltech/icons/SystemV2/IconSystemV2EssentialEyeFilled';
import { IconSystemV2CourseMarkTestMarkTest2 } from '@doltech/icons/SystemV2/IconSystemV2CourseMarkTestMarkTest2';
import { IconSystemV2CourseTestMarkTestGrammar } from '@doltech/icons/SystemV2/IconSystemV2CourseTestMarkTestGrammar';
import { cloneDeepWith } from 'lodash';
import { Homework } from './Homework';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';

const HelpingGroup = styled.div`
  display: flex;
  justify-content: space-between;
  .helping-item-actived {
    border: 2px solid ${colorsV2.primary100};
  }
  .helping-item-unactived {
    border: 1px solid ${colorsV2.grey20};
  }
  ${toScreen(1199)} {
    flex: 1 0 0;
    flex-wrap: wrap;
    ${flexGap([20, 20])}
  }
  ${toScreen(767)} {
    flex-direction: column;
    ${flexGap([0, 20])}
  }
`;
const HelpingItem = styled.div`
  padding: 20px 24px;
  border-radius: 16px;
  width: 32.5%;
  cursor: pointer;
  &:hover {
    background-color: ${colorsV2.grey5};
  }
  ${toScreen(1199)} {
    width: 100%;
    display: flex;
    min-width: 410px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }
  ${toScreen(1110)} {
    width: 100%;
    display: flex;
    min-width: 335px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }
`;

const HelpingTitle = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  .lp-helping-title {
    padding-left: 10px;
  }
  ${toScreen(1199)} {
    display: block;
    .lp-helping-title {
      padding-bottom: 2px;
      padding-left: 0;
    }
  }
`;

export interface IHelping {
  id: number;
  title: string;
  content: string;
  icon: JSX.Element;
  actived: boolean;
}

const helpingInit = [
  {
    id: 1,
    title: 'Giúp theo dõi việc học sát sao',
    content:
      'Nền tảng DOL superLMS số hóa mọi loại nội dung, giúp học viên biết cần làm gì mỗi ngày và giáo viên theo sát học viên.',
    icon: <IconSystemV2EssentialEyeFilled color={colorsV2.grey180} width={24} height={24} />,
    actived: false,
  },
  {
    id: 2,
    title: 'Giúp nhận bài chấm chi tiết hơn',
    content:
      'Bài Writing, và Speaking được chấm sửa chi tiết, kèm giải thích bằng giọng nói thêm trong hệ thống.',
    icon: <IconSystemV2CourseMarkTestMarkTest2 color={colorsV2.grey180} width={24} height={24} />,
    actived: false,
  },
  {
    id: 3,
    title: ' Giúp luyện tập không giới hạn',
    content:
      'Mỗi bài tập đều được kèm giải thích chi tiết, giúp học viên luyện tập mọi lúc thậm chí khi không có thầy cô bên cạnh.',
    icon: <IconSystemV2CourseTestMarkTestGrammar color={colorsV2.grey180} width={24} height={24} />,
    actived: false,
  },
];

export const Helping = () => {
  const [helpings, setHelping] = React.useState(helpingInit);

  const handleHelpingActived = (id: number) => {
    const cloneItems = cloneDeepWith(helpings);
    const item = cloneItems.find((h) => h.id === id);
    const activedItem = cloneItems.find((h) => h.actived === true);
    if (!item.actived) {
      if (activedItem) {
        activedItem.icon = swapIcon(activedItem.id, colorsV2.grey180);
        activedItem.actived = false;
      }
      item.icon = swapIcon(item.id, colorsV2.primary100);
      item.actived = true;
    } else {
      setHelping(helpingInit);
      return;
    }
    setHelping(cloneItems);
  };

  const swapIcon = (id: number, color: string) => {
    switch (id) {
      case 1:
        return <IconSystemV2EssentialEyeFilled color={color} width={24} height={24} />;
      case 2:
        return <IconSystemV2CourseMarkTestMarkTest2 color={color} width={24} height={24} />;
      case 3:
        return <IconSystemV2CourseTestMarkTestGrammar color={color} width={24} height={24} />;
      default:
        break;
    }
  };

  return (
    <>
      <HelpingGroup>
        {helpings.map((h) => {
          return (
            <HelpingItem
              key={h.id}
              onClick={() => handleHelpingActived(h.id)}
              className={`${h.actived ? 'helping-item-actived' : 'helping-item-unactived'}`}
            >
              <HelpingTitle>
                {h.icon}
                <ResponsiveTypography.Paragraph
                  className="lp-helping-title"
                  variant="bold/18-24"
                  color="grey180"
                >
                  {h.title}
                </ResponsiveTypography.Paragraph>
              </HelpingTitle>
              <ResponsiveTypography.Paragraph variant="regular/16-24" color="gray140">
                {h.content}
              </ResponsiveTypography.Paragraph>
            </HelpingItem>
          );
        })}
      </HelpingGroup>
      <Homework helpings={helpings} />
    </>
  );
};
