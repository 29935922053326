import { environment } from '@doltech/core/lib/environment/environment';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { flexGap } from '@doltech/utils/lib/css-style';
import { getCorpWebsiteUrl } from '@doltech/utils/lib/url';
import * as React from 'react';
import styled from 'styled-components';

const FooterTopIntroWrapper = styled.div`
  display: flex;
  ${flexGap(36)}
  align-self: stretch;
  flex-wrap: wrap;
  ${toScreen(959)} {
    flex-direction: column;
    ${flexGap(36, 'column')}
  }
`;
const FooterTopIntroList = styled.div`
  display: flex;
  min-width: 172px;
  flex-direction: column;
  ${flexGap(16, 'column')}
  flex: 1 0 0;
`;
const FooterTopIntroItem = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(8, 'column')}
  align-self: stretch;
  .footer-intro-text {
    span {
      &:hover {
        cursor: pointer;
        color: ${colorsV2.primary80};
      }
    }
  }
`;

const linearSystems = [
  {
    title: 'Từ điển Việt Anh',
    link: `${environment.REACT_APP_DOL_DICTIONARY_WEBSITE_HOST_NAME}`,
  },
  {
    title: 'Tự học IELTS',
    link: 'https://tuhocielts.dolenglish.vn',
  },
  {
    title: 'Ngữ pháp Tiếng Anh',
    link: 'https://grammar.dolenglish.vn',
  },
];

const aboutDol = [
  {
    title: 'Linearthinking',
    link: getCorpWebsiteUrl('/gioi-thieu-linearthinking'),
  },
  {
    title: 'Khóa học IELTS',
    link: getCorpWebsiteUrl('/khoa-hoc-ielts'),
  },
  {
    title: 'Đội ngũ giáo viên',
    link: getCorpWebsiteUrl('/linearteachers'),
  },
  {
    title: 'Thành tích học viên',
    link: getCorpWebsiteUrl('/hall-of-fame'),
  },
];

export const FooterTopIntro = () => {
  return (
    <FooterTopIntroWrapper>
      <FooterTopIntroList>
        <FooterTopIntroItem>
          <ResponsiveTypography.Paragraph variant="regular/16-24" color="grey100">
            DOL LINEARSYSTEM
          </ResponsiveTypography.Paragraph>
          {linearSystems.map((l, index) => {
            return (
              <a key={index} href={l.link} target="_blank" rel="noreferrer">
                <ResponsiveTypography.Paragraph
                  className="footer-intro-text"
                  variant="regular/16-24"
                  color="white100"
                >
                  {l.title}
                </ResponsiveTypography.Paragraph>
              </a>
            );
          })}
        </FooterTopIntroItem>
      </FooterTopIntroList>
      <FooterTopIntroList>
        <FooterTopIntroItem>
          <ResponsiveTypography.Paragraph variant="regular/16-24" color="grey100">
            Về DOL English
          </ResponsiveTypography.Paragraph>
          {aboutDol.map((l, index) => {
            return (
              <a key={index} href={l.link} target="_blank" rel="noreferrer">
                <ResponsiveTypography.Paragraph
                  className="footer-intro-text"
                  variant="regular/16-24"
                  color="white100"
                >
                  {l.title}
                </ResponsiveTypography.Paragraph>
              </a>
            );
          })}
        </FooterTopIntroItem>
      </FooterTopIntroList>
    </FooterTopIntroWrapper>
  );
};
