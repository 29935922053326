import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import * as React from 'react';
import styled from 'styled-components';
import { environment } from '@doltech/core/lib/environment/environment';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { IconSystemV2ArrowChevronRightArrowRight } from '@doltech/icons/SystemV2/IconSystemV2ArrowChevronRightArrowRight';
import { IconSystemV2LogoFacebook2 } from '@doltech/icons/SystemV2/IconSystemV2LogoFacebook2';
import { IconSystemV2BrandsLogoYoutube1 } from '@doltech/icons/SystemV2/IconSystemV2BrandsLogoYoutube1';
import { IconSystemV2BrandsTiktok1 } from '@doltech/icons/SystemV2/IconSystemV2BrandsTiktok1';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';

const FooterTopFrameLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(44, 'column')}
`;

const FooterTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(16, 'column')}
  align-self: stretch;
  .footer-top-content {
    padding-right: 112px;
  }
  ${toScreen(959)} {
    .footer-top-content {
      padding-right: 0;
    }
  }
`;

const FooterLogo = styled.div`
  img {
    width: 212.528px;
    height: 40px;
  }
`;

const FooterTopSystem = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(6)}
  cursor: pointer;
  &:hover {
    span {
      color: ${colorsV2.primary80};
    }
  }
`;

const FooterTopConnectContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(16, 'column')}
`;

const FooterSocial = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(16)}
`;

const FooterSocialItem = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  ${flexGap(8)}
  border-radius: 40px;
  background: ${colorsV2.grey160};
  &:hover {
    cursor: pointer;
    background: ${colorsV2.grey140};
  }
`;

const images = {
  FooterLogo: imgProxyLoader({
    src: 'https://gqefcpylonobj.vcdn.cloud/directus-upload/7946f06a-c1ae-415c-b0ca-5fe04b941ad3.png',
    width: 212,
  }),
};

const socials = [
  {
    id: 'facebook',
    icon: <IconSystemV2LogoFacebook2 width={24} height={24} color={colorsV2.white100} />,
    link: 'https://www.facebook.com/dolvn',
  },
  {
    id: 'youtube',
    icon: <IconSystemV2BrandsLogoYoutube1 width={24} height={24} color={colorsV2.white100} />,
    link: 'https://www.youtube.com/channel/UCEhPLLt928jxgOGDtyzgV9g',
  },
  {
    id: 'tiktok',
    icon: <IconSystemV2BrandsTiktok1 width={24} height={24} color={colorsV2.white100} />,
    link: 'https://www.tiktok.com/@dolenglish',
  },
];

export const FooterTopFrameLeft = () => {
  return (
    <FooterTopFrameLeftWrapper>
      <FooterTopContainer>
        <FooterLogo>
          <img
            width={212}
            height={40}
            loading="lazy"
            alt="footer-logo-slogun"
            src={images.FooterLogo}
          />
        </FooterLogo>
        <ResponsiveTypography.Paragraph
          variant={['SemiBold/24-32', 'SemiBold/28-36', 'SemiBold/32-40']}
          breakpoints={[0, 768, 960]}
          color="white100"
        >
          DOL English - Học Viện Tiếng Anh Tư Duy đầu tiên tại Việt Nam
        </ResponsiveTypography.Paragraph>
        <ResponsiveTypography.Paragraph
          className="footer-top-content"
          variant="regular/16-24"
          color="grey60"
        >
          DOL đang có mặt ở 13 quận tại TP.HCM, 4 cơ sở ở Hà Nội cùng 1 cơ sở ở Đà Nẵng và nền tảng
          Online Zoom.
        </ResponsiveTypography.Paragraph>
        <a
          href={`${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/he-thong-co-so`}
          target="_blank"
          rel="noreferrer"
        >
          <FooterTopSystem>
            <ResponsiveTypography.Paragraph variant="regular/16-24" color="primary60">
              Hệ thống cơ sở của DOL
            </ResponsiveTypography.Paragraph>
            <IconSystemV2ArrowChevronRightArrowRight
              width={20}
              height={20}
              color={colorsV2.primary60}
            />
          </FooterTopSystem>
        </a>
      </FooterTopContainer>
      <FooterTopConnectContainer>
        <ResponsiveTypography.Paragraph variant="regular/16-24" color="grey100">
          KẾT NỐI VỚI DOL
        </ResponsiveTypography.Paragraph>
        <FooterSocial>
          {socials.map((s) => {
            return (
              <a key={s.id} href={s.link} target="_blank" rel="noreferrer">
                <FooterSocialItem>{s.icon}</FooterSocialItem>
              </a>
            );
          })}
        </FooterSocial>
      </FooterTopConnectContainer>
    </FooterTopFrameLeftWrapper>
  );
};
