import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M10.551 9.55v3.297a2.872 2.872 0 00-3.6 2.66 2.593 2.593 0 002.774 2.753 2.585 2.585 0 002.773-2.791V2.4h3.262c.554 3.452 2.28 4.253 4.592 4.622v3.308a9.833 9.833 0 01-4.5-1.52v6.534c0 2.96-1.752 6.256-6.102 6.256a6.13 6.13 0 01-6.102-6.287 6.012 6.012 0 016.903-5.763z" /></svg>;
});
export const IconSystemV2BrandsTiktok1 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));