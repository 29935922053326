import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { IconSystemV2ColoredColorComputerView2 } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredColorComputerView2';
import { IconSystemV2ColoredCourseColorTest } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredCourseColorTest';
import { IconSystemV2ColoredCourseColorSpeaking } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredCourseColorSpeaking';
import { IconSystemV2ColoredColorAI } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredColorAI';
import { IconSystemV2ColoredCourseColorGrammar } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredCourseColorGrammar';
import { IconSystemV2ColoredColorNotebookCourse } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredColorNotebookCourse';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { FeatureVideoPlayer } from './FeatureVideoPlayer';

const FeatureClipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${flexGap(60, 'column')}
  align-self: stretch;
  .clip-item-reverse {
    flex-direction: row-reverse;
  }
  .clip-item-unset {
    flex-direction: unset;
  }
  ${toScreen(1199)} {
    ${flexGap(32, 'column')}
  }
  ${toScreen(767)} {
    ${flexGap(20, 'column')}
  }
`;
const FeatureClip = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid ${colorsV2.grey20};
  .clip-content-right {
    padding: 32px 60px 48px 40px;
  }
  .clip-content-left {
    padding: 32px 60px 48px;
  }
  .clip-radius-right {
    border-radius: 20px 0 0 20px;
  }
  .clip-radius-left {
    border-radius: 0 20px 20px 0;
  }
  ${toScreen(959)} {
    display: block;
    height: 100%;
    .clip-radius-right {
      border-radius: 0 0 20px 20px;
    }
    .clip-radius-left {
      border-radius: 0 0 20px 20px;
    }
    .clip-content-right,
    .clip-content-left {
      padding: 24px;
    }
  }
  ${toScreen(767)} {
    display: block;
    height: 100%;
    .clip-radius-right {
      border-radius: 0 0 20px 20px;
    }
    .clip-radius-left {
      border-radius: 0 0 20px 20px;
    }
    .clip-content-right,
    .clip-content-left {
      padding: 24px 20px;
    }
  }
`;
const FeatureContent = styled.div`
  display: flex;
  min-width: 380px;
  max-width: 520px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${flexGap(32, 'column')}
  flex: 1 0 0;
  border-radius: 8px;
  ${toScreen(1199)} {
    padding: 32px 40px;
    min-width: unset;
  }
  ${toScreen(959)} {
    flex-direction: row;
    ${flexGap(24)}
    max-width: unset;
    padding: 24px;
  }
  ${toScreen(767)} {
    max-width: unset;
    padding: 24px 20px;
  }
`;

const FeatureIcon = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  ${flexGap(8)}
  border-radius: 16px;
  background: ${colorsV2.primary5};
  ${toScreen(1199)} {
    display: none;
  }
  ${toScreen(959)} {
    display: flex;
    padding: 12px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  ${toScreen(767)} {
    display: none;
  }
`;

const FeatureText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${flexGap(12, 'column')}
  align-self: stretch;
`;

const FeatureTextContent = styled.div`
  display: flex;
  padding-right: 40px;
  align-items: flex-start;
  ${flexGap(8)}
  align-self: stretch;
`;

export interface IFeature {
  icon: JSX.Element;
  title: string;
  content: string;
  videoUrl: string;
  direction: string;
  posterImg: string;
}

const features = [
  {
    icon: <IconSystemV2ColoredColorComputerView2 />,
    title: 'Thống kê điểm danh và luyện tập trong khóa học',
    content: 'Giúp học viên theo dõi tiến độ đi học và tiến độ luyện tập trong toàn quá trình học.',
    videoUrl:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/a06aa9b1-77f5-4c85-b556-9e4afcfa2323.mp4',

    direction: 'left',
    posterImg:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/2bb20f02-73fe-4c38-bda7-30e51c9ecf2b.png',
  },
  {
    icon: <IconSystemV2ColoredCourseColorTest size={52} />,
    title: 'Kho bài tập được soạn riêng cho từng khóa học',
    content:
      'Giúp cho việc học đạt hiệu quả tối đa bằng các bài bổ trợ về nhà để ôn lại tư duy được học trên lớp, và nhuần nhuyễn chúng. ',
    videoUrl:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/6c618879-97f1-4f47-bbdc-336eeccaa45b.mp4',
    direction: 'right',
    posterImg:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/645c3abb-3e3d-4245-bc6e-7c709f449c75.png',
  },
  {
    icon: <IconSystemV2ColoredCourseColorSpeaking size={52} />,
    title: 'Bài chấm Writing/Speaking kèm giọng nói',
    content:
      'Giúp học viên làm bài tập W/S dễ hơn, biết lúc nào hết hạn, và được chấm sửa chi tiết hơn, nhanh hơn với giọng nói thu sẵn từ giáo viên.',
    videoUrl:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/2c880ce8-3aee-42e0-9273-405a6e164920.mp4',
    direction: 'left',
    posterImg:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/c9bced14-1189-4ef8-baa4-34a98f564f90.png',
  },
  {
    icon: <IconSystemV2ColoredColorAI />,
    title: 'AI Writing & Speaking mock test áp dụng trí tuệ nhân tạo ',
    content: 'Giúp học viên luyện tập thêm W/S được chấm chữa chi tiết và gần như tức thì.',
    videoUrl:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/d04c2bb5-d97f-4f2e-b59c-553c2f3e294d.mp4',
    direction: 'right',
    posterImg:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/6623b0ad-fbbb-4586-867b-e0897531c800.png',
  },
  {
    icon: <IconSystemV2ColoredColorNotebookCourse size={52} />,
    title: 'Giao diện thi thật 4 kỹ năng',
    content:
      'Cung cấp giao diện thi thật cho cả 4 kỹ năng, với nội dung thi thật, giúp học viên chuẩn bị tốt nhất trước kỳ thi.',
    videoUrl:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/879475c2-6aa8-405a-b770-399f24d748a7.mp4',
    direction: 'left',
    posterImg:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/8460bce9-e957-44a5-ac75-cc6d08109d25.png',
  },
  {
    icon: <IconSystemV2ColoredCourseColorGrammar size={52} />,
    title: 'Đa dạng công cụ luyện tập, tạo trải nghiệm all-in-one',
    content:
      'DOL phát triển gần như hết tất cả tính năng cần thiết để học Tiếng Anh, từ app Vocabulary, đến chép chính tả, mini exercise, online test R/L, sample W/S để giúp học viên không cần đi đâu khác.',
    videoUrl:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/f837bc4e-5923-40ed-bb0f-4c3a104a2e2a.mp4',
    direction: 'right',
    posterImg:
      'https://gqefcpylonobj.vcdn.cloud/directus-upload/6aea3ec6-970a-4581-b0c7-5c74bbca2b5d.png',
  },
];

export const ListFeature = () => {
  return (
    <FeatureClipContainer>
      {features.map((f, index) => {
        return (
          <FeatureClip
            key={index}
            className={`${f.direction === 'right' ? 'clip-item-reverse' : 'clip-item-unset'}`}
          >
            <FeatureContent
              className={`${f.direction === 'right' ? 'clip-content-right' : 'clip-content-left'}`}
            >
              <FeatureIcon>{f.icon}</FeatureIcon>
              <FeatureText>
                <ResponsiveTypography.Paragraph variant="SemiBold/24-32" color="grey180">
                  {f.title}
                </ResponsiveTypography.Paragraph>
                <FeatureTextContent>
                  <ResponsiveTypography.Paragraph variant="regular/16-24" color="gray140">
                    {f.content}
                  </ResponsiveTypography.Paragraph>
                </FeatureTextContent>
              </FeatureText>
            </FeatureContent>
            <FeatureVideoPlayer feature={f} />
          </FeatureClip>
        );
      })}
    </FeatureClipContainer>
  );
};
