import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import styled, { css } from 'styled-components';
import { colorsV2 } from '../colors-v2';

ChartJS.register(ArcElement);

const Bar = styled.div<{ size?: number }>`
  ${(p) => {
    if (p.size) {
      return css`
        width: ${p.size}px;
        min-width: ${p.size}px;
        height: ${p.size}px;
      `;
    }
    return null;
  }}
`;

interface ProgressBarCircularProps {
  /*
   * percentage go from 0 to 100
   * */
  percentage?: number;
  barColor?: string;
  progressColor?: string;
  cutout?: string;
  borderRadius?: number;
  size?: number;
}

export const ProgressBarCircular = (props: ProgressBarCircularProps) => {
  const {
    percentage = 0,
    barColor = colorsV2.green10,
    progressColor = colorsV2.gradientGreen,
    borderRadius = 8,
    size,
    cutout = '85%',
  } = props;

  return (
    <Bar className="progress-bar-circular" size={size}>
      <Doughnut
        options={{
          cutout,
          animation: false,
        }}
        data={{
          datasets: [
            {
              data: [percentage, 100 - percentage],
              backgroundColor: [progressColor, barColor],
              borderWidth: 0,
              borderRadius: percentage < 100 ? borderRadius : 0,
            },
          ],
        }}
      />
    </Bar>
  );
};
