import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { ProgressBarCircular } from '@doltech/ui/lib/figma/ProgressBarCircular/ProgressBarCircular';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { IFeature } from './ListFeature';
import { IconSystemV2CoursePlay } from '@doltech/icons/SystemV2/IconSystemV2CoursePlay';
import { IconSystemV2CoursePause } from '@doltech/icons/SystemV2/IconSystemV2CoursePause';
import { isIos } from '@doltech/utils/lib/platform';
import { useInView } from 'react-cool-inview';

const START_TIME_IN_SECONDS = 0.001;

const FeatureImageWrapper = styled.div`
  display: flex;
  min-width: 520px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background: ${colorsV2.grey10};

  ${toScreen(1199)} {
    min-width: unset;
  }
  ${toScreen(959)} {
    min-width: unset;
    border-radius: 0 0 20px 20px;
  }
  ${toScreen(767)} {
    min-width: unset;
    border-radius: 0 0 20px 20px;
  }
`;

const FeatureImageContent = styled.div`
  display: flex;
  width: 612px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${colorsV2.grey10};

  ${toScreen(959)} {
    border: none;
  }
  ${toScreen(767)} {
    width: 100%;
    height: 100%;
    border-radius: 0 0 20px 20px;
  }
`;

const FeatureImgContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 56.25%;
  cursor: pointer;
  :hover {
    img {
      opacity: 0;
    }
    video {
      opacity: 100;
    }
    .lp-video-control-box {
      opacity: 100;
    }
  }

  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }

  video {
    position: absolute;
    top: 0;
    opacity: 100;
    width: 100%;
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    pointer-events: auto;
  }
  ${toScreen(767)} {
    img {
      display: none;
    }
    video {
      border-radius: 0 20px 20px 0;
      object-fit: contain;
    }
  }
`;

const VideoControlBox = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  ${flexGap(6)}
  position: absolute;
  right: 16px;
  bottom: 14px;
  border-radius: 20px;
  border: 1px solid ${colorsV2.gray10};
  background: ${colorsV2.white40};
  backdrop-filter: blur(5px);
`;

const VideoControlItem = styled.div`
  display: flex;
`;

const ProgressIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;
  canvas {
    width: 18px;
    height: 18px;
  }
  ${toScreen(767)} {
    display: none;
  }
`;

interface IProps {
  feature: IFeature;
}
export const FeatureVideoPlayer = (props: IProps) => {
  const { feature } = props;
  const itemsRef = React.useRef<HTMLVideoElement>(null);
  const [isHoverToPlay, setIsHoverToPlay] = React.useState(false);
  const [isPausedVideo, setIsPausedVideo] = React.useState<boolean>(true);
  const [videoDuration, setVideoDuration] = React.useState<number>(0);
  const [currentTime, setCurrentTime] = React.useState<number>(0);

  const handeMouseEnterToPlay = () => {
    if (itemsRef?.current) {
      if (!isHoverToPlay) {
        itemsRef.current.play();
      }
      setIsHoverToPlay(true);
    }
  };

  const handleMouseLeaveToPause = () => {
    if (itemsRef?.current) {
      itemsRef.current.pause();
      setIsHoverToPlay(false);
    }
  };

  const handePauseVideo = () => {
    if (itemsRef?.current) {
      itemsRef.current.pause();
    }
  };

  const handlePlayVideo = () => {
    if (itemsRef?.current) {
      itemsRef.current.play();
    }
  };

  const handleTimeUpdate = (el: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    if (itemsRef?.current) {
      if (videoDuration === 0) {
        setVideoDuration(el.currentTarget.duration);
      }
      setCurrentTime(el.currentTarget.currentTime);
    }
  };

  const getRadiusClass = () => {
    return feature.direction === 'right' ? 'clip-radius-right' : 'clip-radius-left';
  };

  const { observe, inView } = useInView({
    unobserveOnEnter: true,
    rootMargin: '50px',
  });

  return (
    <FeatureImageWrapper ref={observe} className={getRadiusClass()}>
      <FeatureImageContent className={getRadiusClass()}>
        <FeatureImgContainer
          className={getRadiusClass()}
          onMouseLeave={typeof window !== 'undefined' && !isIos() ? handleMouseLeaveToPause : null}
          onMouseEnter={typeof window !== 'undefined' && !isIos() ? handeMouseEnterToPlay : null}
        >
          <img
            loading="lazy"
            alt="intro_img"
            src={feature.posterImg}
            className={getRadiusClass()}
          />
          {inView && (
            <video
              className={getRadiusClass()}
              ref={itemsRef}
              src={`${feature.videoUrl}#t=${START_TIME_IN_SECONDS}`}
              preload="auto"
              width="100%"
              height="100%"
              loop
              muted
              playsInline
              onTimeUpdate={(el: React.SyntheticEvent<HTMLVideoElement, Event>) =>
                handleTimeUpdate(el)
              }
              onPlay={() => setIsPausedVideo(false)}
              onPause={() => setIsPausedVideo(true)}
            />
          )}

          <VideoControlBox className="lp-video-control-box">
            <ProgressIcon>
              <ProgressBarCircular
                barColor={colorsV2.red10}
                progressColor={colorsV2.red100}
                percentage={(currentTime / videoDuration) * 100 ?? 0}
                cutout="48%"
              />
            </ProgressIcon>
            {isPausedVideo ? (
              <VideoControlItem onClick={() => handlePlayVideo()}>
                <IconSystemV2CoursePlay size={18} color={colorsV2.blue100} />
              </VideoControlItem>
            ) : (
              <VideoControlItem onClick={() => handePauseVideo()}>
                <IconSystemV2CoursePause size={18} color={colorsV2.blue100} />
              </VideoControlItem>
            )}
          </VideoControlBox>
        </FeatureImgContainer>
      </FeatureImageContent>
    </FeatureImageWrapper>
  );
};
