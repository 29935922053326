import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        d="M22 12.062C22 6.503 17.52 2 12 2 6.476 2 2 6.503 2 12.062c0 5.02 3.656 9.184 8.438 9.938v-7.029h-2.54v-2.908h2.54V9.845c0-2.522 1.49-3.914 3.775-3.914 1.095 0 2.24.196 2.24.196v2.476H15.19c-1.24 0-1.627.777-1.627 1.572v1.887h2.773l-.446 2.909h-2.328v7.028C18.341 21.245 22 17.082 22 12.061z"
      />
    </svg>
  );
});
export const IconSystemV2LogoFacebook2 = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
