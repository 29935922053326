import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" d="M21.44 8.16s-.16-1.36-.8-1.92c-.72-.8-1.52-.8-1.92-.8C16 5.28 12 5.28 12 5.28s-4 0-6.72.16c-.4.08-1.2.08-1.92.8-.56.56-.8 1.92-.8 1.92s-.16 1.52-.16 3.12v1.44c0 1.52.16 3.12.16 3.12s.16 1.36.8 1.92c.72.8 1.68.72 2.08.8 1.52.16 6.56.16 6.56.16s4 0 6.72-.24c.4-.08 1.2-.08 1.92-.8.56-.56.8-1.92.8-1.92s.16-1.52.16-3.12V11.2c0-1.52-.16-3.04-.16-3.04zM10 14.48V9.12l5.2 2.72-5.2 2.64z" /></svg>;
});
export const IconSystemV2BrandsLogoYoutube1 = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));