import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { FooterTop } from './FooterTop';
import { FooterBottom } from './FooterBottom';
import { IconSystemV2CourseArrowArrowUpLine } from '@doltech/icons/SystemV2/IconSystemV2CourseArrowArrowUpLine';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { LAYER } from '@doltech/utils/lib/constants';

const LmsStudentLandingPageFooter = styled.div`
  padding: 72px 117px;
  background: ${colorsV2.grey180};
  ${toScreen(1366)} {
    padding: 72px 34px;
  }
  ${toScreen(1119)} {
    padding: 72px 32px;
  }
  ${toScreen(959)} {
    padding: 72px 32px;
  }
  ${toScreen(767)} {
    padding: 72px 24px;
  }
`;

const FooterContainer = styled.div`
  max-width: 1132px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ButtonToTop = styled.div`
  display: flex;
  padding: 10px 12px;
  align-items: center;
  ${flexGap(2)}
  border-radius: 20px;
  border: 1px solid ${colorsV2.grey160};
  background: ${colorsV2.grey180};
  box-shadow: ${shadows.neutralShadowToBot3};
  cursor: pointer;
  position: fixed;
  right: 47px;
  bottom: 100px;
  z-index: ${LAYER.LAYER1};
  .footer-to-top-text {
    padding: 0 4px;
  }
  ${toScreen(1366)} {
    right: 55px;
  }
  ${toScreen(1199)} {
    right: 47px;
  }
  ${toScreen(767)} {
    right: 35px;
    bottom: 20px;
  }
`;

interface IProps {
  handleScrollToTop?: () => void;
  isShowToTop?: boolean;
}

export const LandingPageFooter = (props: IProps) => {
  const { handleScrollToTop, isShowToTop } = props;
  return (
    <LmsStudentLandingPageFooter>
      <FooterContainer>
        <FooterTop />
        <FooterBottom />
        {isShowToTop && (
          <ButtonToTop onClick={handleScrollToTop}>
            <IconSystemV2CourseArrowArrowUpLine width={20} height={20} color={colorsV2.grey80} />
            <ResponsiveTypography.Paragraph
              className="footer-to-top-text"
              variant="regular/14-20"
              color="grey5"
            >
              Lên đầu
            </ResponsiveTypography.Paragraph>
          </ButtonToTop>
        )}
      </FooterContainer>
    </LmsStudentLandingPageFooter>
  );
};
