import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M46.5833 19.9905C46.5833 15.4909 43.2465 11.7615 38.9348 11.1821C38.3615 7.31167 35.0399 4.33301 31.0408 4.33301C31.0274 4.33301 31.0142 4.33301 31.0008 4.33315C28.9262 4.34377 27.2602 6.06534 27.2602 8.15538V9.56343C27.2602 10.2645 26.696 10.833 26 10.833C25.304 10.833 24.7397 10.2647 24.7397 9.56343V8.15538C24.7397 6.06548 23.0737 4.34377 20.9991 4.33315C20.9857 4.33315 20.9725 4.33301 20.9591 4.33301C16.96 4.33301 13.6384 7.31167 13.0653 11.1821C8.75355 11.7617 5.41662 15.4909 5.41662 19.9905C5.41662 22.2479 6.25142 24.3711 7.74541 25.9997C6.25128 27.6283 5.41662 29.7513 5.41662 32.0088C5.41662 36.5084 8.75341 40.2378 13.0653 40.8172C13.6384 44.6877 16.9601 47.6663 20.9591 47.6663C20.9725 47.6663 20.9857 47.6663 20.9991 47.6662C23.0737 47.6556 24.7397 45.934 24.7397 43.844V42.4359C24.7397 41.7348 25.3039 41.1663 26 41.1663C26.6959 41.1663 27.2602 41.7347 27.2602 42.4359V43.844C27.2602 45.9339 28.9262 47.6556 31.0008 47.6662C31.0142 47.6662 31.0274 47.6663 31.0408 47.6663C35.0399 47.6663 38.3615 44.6877 38.9348 40.8172C43.2465 40.2377 46.5833 36.5084 46.5833 32.0088C46.5833 29.7515 45.7485 27.6283 44.2545 25.9997C45.7485 24.3711 46.5833 22.2479 46.5833 19.9905Z"
        fill="url(#paint0_linear_6924_5126)"
      />
      <path
        d="M30.5448 31.0665C30.6717 31.3344 30.7352 31.5953 30.7352 31.8491C30.7352 32.3145 30.5448 32.7093 30.1641 33.0337C29.7974 33.358 29.3744 33.5202 28.8949 33.5202C28.5705 33.5202 28.2744 33.4356 28.0065 33.2664C27.7385 33.0971 27.527 32.8433 27.3719 32.5049L26.42 30.3684H19.5664L18.5934 32.5049C18.4383 32.8433 18.2197 33.0971 17.9377 33.2664C17.6697 33.4356 17.3736 33.5202 17.0492 33.5202C16.5698 33.5202 16.1326 33.358 15.7378 33.0337C15.357 32.7093 15.1666 32.3145 15.1666 31.8491C15.1666 31.5953 15.2301 31.3344 15.357 31.0665L20.9414 19.6016C21.1247 19.2208 21.3926 18.9317 21.7452 18.7343C22.1118 18.5228 22.5138 18.417 22.9509 18.417C23.374 18.417 23.7688 18.5228 24.1355 18.7343C24.5021 18.9317 24.7771 19.2208 24.9604 19.6016L30.5448 31.0665ZM20.8779 27.4493H25.1085L22.9932 22.7322L20.8779 27.4493Z"
        fill="url(#paint1_linear_6924_5126)"
      />
      <path
        d="M34.2834 33.5837C33.6912 33.5837 33.2187 33.4144 32.8662 33.076C32.5277 32.7234 32.3585 32.251 32.3585 31.6587V20.3419C32.3585 19.7637 32.5277 19.3054 32.8662 18.967C33.2187 18.6144 33.6912 18.4381 34.2834 18.4381C34.8898 18.4381 35.3693 18.6144 35.7218 18.967C36.0744 19.3054 36.2507 19.7637 36.2507 20.3419V31.6587C36.2507 32.251 36.0744 32.7234 35.7218 33.076C35.3693 33.4144 34.8898 33.5837 34.2834 33.5837Z"
        fill="url(#paint2_linear_6924_5126)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6924_5126"
          x1="41.1162"
          y1="43.89"
          x2="17.6293"
          y2="6.21195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D14242" />
          <stop offset="1" stopColor="#F18989" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6924_5126"
          x1="19.2092"
          y1="14.0665"
          x2="44.3786"
          y2="49.0275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6924_5126"
          x1="19.2092"
          y1="14.0665"
          x2="44.3786"
          y2="49.0275"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
});
export const IconSystemV2ColoredColorAI = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
