import * as React from 'react';
import styled from 'styled-components';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';

const FooterBottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  .footer-bottom-content-text {
    flex: 1 0 0;
  }
  img {
    width: 102px;
    height: 24px;
    cursor: pointer;
  }
`;

const images = {
  FooterDMCA:
    'https://gqefcpylonobj.vcdn.cloud/directus-upload/5a5a5ab6-6cb7-4c9d-9de5-dcb71253c063.png',
};

export const FooterBottom = () => {
  return (
    <FooterBottomContent>
      <ResponsiveTypography.Paragraph
        className="footer-bottom-content-text"
        variant="regular/14-20"
        color="grey100"
      >
        © {new Date().getFullYear()} DOL English. All rights reserved.
      </ResponsiveTypography.Paragraph>
      <a
        href="https://www.dmca.com/Protection/Status.aspx?ID=36c8b284-aa13-4f7c-96a6-e7e419ec8411"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="footer-logo-slogun" src={images.FooterDMCA} />
      </a>
    </FooterBottomContent>
  );
};
