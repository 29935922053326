/* stylelint-disable value-no-vendor-prefix */
import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { LAYER } from '@doltech/utils/lib/constants';
import { touchDevice } from '@doltech/core/lib/responsive/responsive.util';

const Body = styled.article`
  display: grid;
`;

const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: ${LAYER.LAYER2};

  display: grid;
  grid-template-rows: minmax(0, 1fr);
  align-items: flex-end;

  width: 100%;
`;

const Header = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${LAYER.LAYER2};

  display: grid;
  grid-template-rows: minmax(0, 1fr);
  align-items: flex-start;

  width: 100%;
`;

const Container = styled.main`
  display: grid;
  grid-template-rows: [first] auto [second] minmax(0, 1fr) [third] auto [last];

  height: 100%;

  .layout-header {
    grid-row: first / second;
  }

  .layout-body {
    grid-row: second / third;
  }

  .layout-footer {
    grid-row: third / last;
  }

  &.full-height {
    height: 100vh;

    ${touchDevice()} {
      height: -webkit-fill-available;
    }

    .layout-body {
      display: block;
      max-height: 100%;
      overflow: hidden;
    }
  }
`;

interface LayoutPageHamburgerProps {
  className?: string;
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  fullHeight?: boolean;
  bgColor?: string;
}

export const LayoutPageHamburger = (props: LayoutPageHamburgerProps) => {
  const { className, header, children, footer, fullHeight = false, bgColor } = props;

  return (
    <Container
      className={cl('layout-page-hamburger', { 'full-height': fullHeight }, className)}
      style={{ backgroundColor: bgColor }}
    >
      {header && <Header className="layout-header">{header}</Header>}
      <Body className="layout-body">{children}</Body>
      {footer && <Footer className="layout-footer">{footer}</Footer>}
    </Container>
  );
};
