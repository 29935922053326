import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M4.33337 32.4997C4.33337 36.0895 7.24352 38.9997 10.8334 38.9997L41.1667 38.9997C44.7566 38.9997 47.6667 36.0895 47.6667 32.4997V10.833C47.6667 7.24316 44.7566 4.33301 41.1667 4.33301H10.8334C7.24352 4.33301 4.33337 7.24316 4.33337 10.833V32.4997Z"
        fill="url(#paint0_linear_6924_15036)"
      />
      <path
        d="M17.3334 43.333C16.1368 43.333 15.1667 44.3031 15.1667 45.4997C15.1667 46.6963 16.1368 47.6663 17.3334 47.6663H34.6667C35.8633 47.6663 36.8334 46.6963 36.8334 45.4997C36.8334 44.3031 35.8633 43.333 34.6667 43.333H17.3334Z"
        fill="url(#paint1_linear_6924_15036)"
      />
      <path
        d="M26 13C20.0909 13 15.0445 16.5938 13 21.6667C15.0445 26.7396 20.0909 30.3333 26 30.3333C31.9091 30.3333 36.9555 26.7396 39 21.6667C36.9555 16.5938 31.9091 13 26 13ZM26 27.4444C22.7382 27.4444 20.0909 24.856 20.0909 21.6667C20.0909 18.4773 22.7382 15.8889 26 15.8889C29.2618 15.8889 31.9091 18.4773 31.9091 21.6667C31.9091 24.856 29.2618 27.4444 26 27.4444ZM26 18.2C24.0382 18.2 22.4545 19.7484 22.4545 21.6667C22.4545 23.5849 24.0382 25.1333 26 25.1333C27.9618 25.1333 29.5455 23.5849 29.5455 21.6667C29.5455 19.7484 27.9618 18.2 26 18.2Z"
        fill="url(#paint2_linear_6924_15036)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6924_15036"
          x1="41.9118"
          y1="43.89"
          x2="18.9734"
          y2="5.1551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D14242" />
          <stop offset="1" stopColor="#F18989" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6924_15036"
          x1="41.9118"
          y1="43.89"
          x2="18.9734"
          y2="5.1551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D14242" />
          <stop offset="1" stopColor="#F18989" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6924_15036"
          x1="11.375"
          y1="11.2667"
          x2="37.5992"
          y2="9.68645"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.8" />
          <stop offset="1" stopColor="white" stopOpacity="0.4" />
        </linearGradient>
      </defs>
    </svg>
  );
});
export const IconSystemV2ColoredColorComputerView2 = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
