import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { IconSystemV2SpecificFireFlame2 } from '@doltech/icons/SystemV2Colored/IconSystemV2SpecificFireFlame2';
import { ListValueBrought } from './ListValueBrought';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';

const ValueBroughtSection = styled.div`
  padding: 80px 0 40px;
  ${toScreen(767)} {
    padding: 52px 0 40px;
  }
`;
const ValueBroughtContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(40, 'column')}
  align-self: stretch;
  scroll-margin: 80px;
  ${toScreen(1199)} {
    ${flexGap(32, 'column')}
  }
`;

const ValueBroughtHeading = styled.div`
  display: flex;
  max-width: 540px;
  flex-direction: column;
  justify-content: flex-end;
  ${flexGap(20, 'column')}
  align-self: stretch;
`;

const ValueBroughtBox = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;
const ValueBroughtBoxBorder = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colorsV2.grey40};
  border-radius: 20px;
  padding: 6px 12px;
  box-shadow: ${shadows.neutralShadowToBot3};
  .lp-connect-text {
    padding-left: 6px;
  }
`;

export const ValueBrought = React.forwardRef((props, ref?: React.Ref<HTMLDivElement>) => {
  return (
    <ValueBroughtSection>
      <ValueBroughtContainer ref={ref}>
        <ValueBroughtHeading>
          <ValueBroughtBox>
            <ValueBroughtBoxBorder>
              <IconSystemV2SpecificFireFlame2 width={20} height={20} color={colorsV2.primary100} />
              <ResponsiveTypography.Paragraph
                className="lp-connect-text"
                variant="regular/14-20"
                color="grey180"
              >
                Blended-smart Learning
              </ResponsiveTypography.Paragraph>
            </ValueBroughtBoxBorder>
          </ValueBroughtBox>
          <ResponsiveTypography.Paragraph
            variant={['bold/28-36', 'bold/32-40', 'bold/40-48']}
            breakpoints={[0, 768, 960]}
            color="grey180"
          >
            Tăng x4 hiệu quả việc học của học viên tại DOL
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph variant="regular/16-28" color="grey140">
            DOL superLMS là mảnh ghép mô hình giảng dạy Blended-Smart Learning - sự kết hợp tinh tế
            giữa phương pháp giảng dạy phi truyền thống và ứng dụng công nghệ tiên tiến, mang đến
            cho học viên một trải nghiệm học tập đột phá và hiệu quả x4.
          </ResponsiveTypography.Paragraph>
        </ValueBroughtHeading>
        <ListValueBrought />
      </ValueBroughtContainer>
    </ValueBroughtSection>
  );
});
