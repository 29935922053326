import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { IconSystemV2CourseQuestionCircleFill } from '@doltech/icons/SystemV2/IconSystemV2CourseQuestionCircleFill';
import { Helping } from './Helping';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';

const UpgradeReasonWrapper = styled.div`
  padding: 140px 0 40px;
  max-width: 590px;
  ${toScreen(1199)} {
    padding: 140px 0 32px;
  }
  ${toScreen(959)} {
    padding: 120px 0 32px;
  }
  ${toScreen(767)} {
    padding: 108px 0 32px;
  }
`;
const UpgradeReasonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
  scroll-margin: 80px;
`;
const UpgradeReasonBoxBorder = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colorsV2.grey40};
  border-radius: 20px;
  padding: 6px 12px;
  box-shadow: ${shadows.neutralShadowToBot3};
  ${flexGap(6)}
`;

const UpgradeReasonTitleContainer = styled.div`
  padding-bottom: 16px;
`;

export const UpgradeReason = React.forwardRef((props, ref?: React.Ref<HTMLDivElement>) => {
  return (
    <>
      <UpgradeReasonWrapper>
        <UpgradeReasonBox ref={ref}>
          <UpgradeReasonBoxBorder>
            <IconSystemV2CourseQuestionCircleFill size={20} color={colorsV2.primary100} />
            <ResponsiveTypography.Paragraph variant="regular/14-20" color="grey180">
              Lý do phát triển
            </ResponsiveTypography.Paragraph>
          </UpgradeReasonBoxBorder>
        </UpgradeReasonBox>
        <UpgradeReasonTitleContainer>
          <ResponsiveTypography.Title
            level={1}
            variant={['bold/28-36', 'bold/32-40', 'bold/40-48']}
            breakpoints={[0, 768, 960]}
          >
            Khát khao nâng cao trải nghiệm của học viên
          </ResponsiveTypography.Title>
        </UpgradeReasonTitleContainer>
        <ResponsiveTypography.Paragraph
          className="reason-text-content"
          variant="regular/16-28"
          color="gray140"
        >
          Xuất phát từ mong muốn nâng cao trải nghiệm của học viện trong từng bước, DOL đã đầu tư
          rất nhiều tâm huyết để hoàn thiện không những chương trình học, phương pháp học mà còn là
          nền tảng công nghệ để hỗ trợ việc học tại nhà để của các bạn học viên tốt hơn.
        </ResponsiveTypography.Paragraph>
      </UpgradeReasonWrapper>
      <Helping />
    </>
  );
});
