import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="currentColor" fillRule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm-1.249-7.921c-.031.274.195.499.472.499H12.4c.276 0 .49-.225.523-.5.006-.05.013-.097.022-.137.03-.145.116-.333.257-.562.075-.115.161-.225.257-.33.1-.11.212-.225.333-.345.126-.125.267-.27.423-.434.363-.385.668-.8.915-1.244.246-.445.37-.94.37-1.484 0-.34-.06-.677-.181-1.011a2.745 2.745 0 00-.514-.892 2.76 2.76 0 00-1.263-.87 4.843 4.843 0 00-1.625-.269c-.348 0-.696.05-1.043.15-.348.1-.666.245-.953.434a2.9 2.9 0 00-.96 1.05c-.16.287-.28.593-.362.918-.073.29.162.55.46.55h1.105c.281 0 .493-.24.593-.503a.981.981 0 01.329-.426 1.1 1.1 0 01.415-.195c.166-.04.335-.06.507-.06.226 0 .438.038.635.113.201.07.362.187.483.352a.764.764 0 01.152.33c.03.124.045.254.045.39 0 .259-.035.499-.106.719-.066.214-.202.434-.408.659-.267.28-.5.497-.696.652a6.841 6.841 0 00-.514.457 2.232 2.232 0 00-.408.6 3.39 3.39 0 00-.355.899 4.504 4.504 0 00-.085.49zm-.028 2.694a1.08 1.08 0 002.161 0 1.08 1.08 0 00-2.162 0z" clipRule="evenodd" /></svg>;
});
export const IconSystemV2CourseQuestionCircleFill = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));