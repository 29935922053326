import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import * as React from 'react';
import styled from 'styled-components';
import { IconSystemV2CourseColorMemorise } from '@doltech/icons/SystemV2/IconSystemV2CourseColorMemorise';
import { IconSystemV2ColoredCourseColorHeart } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredCourseColorHeart';
import { IconSystemV2ColoredCourseColorComputer2 } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredCourseColorComputer2';
import { IconSystemV2ColoredCourseColorBook } from '@doltech/icons/SystemV2Colored/IconSystemV2ColoredCourseColorBook';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';

const ValueBroughtList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  ${toScreen(959)} {
    grid-template-columns: 1fr;
  }
`;
const ValueBroughtListItem = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  ${flexGap(20, 'column')}
  flex: 1 0 0;
  border-radius: 32px;
  border: 1px solid ${colorsV2.grey20};
  background: ${colorsV2.white100};
  ${toScreen(959)} {
    padding: 24px;
  }
`;
const ValueBroughtListIcon = styled.div`
  display: flex;
  padding: 12px;
  ${flexGap(8)}
  border-radius: 20px;
  background: ${colorsV2.primary10};
`;
const ValueBroughtListText = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(12, 'column')}
  align-self: stretch;
`;

const ValueBroughtListTextValue = styled.div`
  padding-right: 40px;
`;

const valueBroughts = [
  {
    icon: <IconSystemV2CourseColorMemorise width={40} height={40} />,
    title: 'Phương pháp học thông minh x2 Linearthinking',
    content:
      'Được phát triển bởi DOL, phương pháp này giúp học viên tư duy logic và học từ bản chất, tạo nền tảng vững chắc chặng đầu và sau đó là bứt tốc nhanh vào chặng sau.',
  },
  {
    icon: <IconSystemV2ColoredCourseColorHeart width={40} height={40} />,
    title: 'Học với thầy/cô kỹ năng sư phạm tốt và tận tâm theo sát học viên x2',
    content:
      'Thầy/cô giảng bài dễ hiểu giúp học viên tiếp thu nhanh và dễ hơn. Thầy/cô theo sát vừa đảm bảo giúp học viên gỡ vướng mắc, vừa tạo động lực cho học viên.',
  },
  {
    icon: <IconSystemV2ColoredCourseColorComputer2 width={40} height={40} />,
    title: 'Nền tảng công nghệ dol superLMS, giúp luyện tập tối đa ở nhà x2',
    content:
      'Nền tảng superLMS giúp học viên có thể học mọi lúc, mọi nơi với đầy đủ tính năng, thiết kế đẹp, nội dung tốt và rất chi tiết. Điều này giúp tiết kiệm tối đa thời gian học.',
  },
  {
    icon: <IconSystemV2ColoredCourseColorBook width={40} height={40} />,
    title: 'Nền tảng giúp giáo viên theo dõi sát sao học viên, để hỗ trợ thêm hiệu quả x2',
    content:
      'Giáo viên có thể sát sao tiến trình học tập của học viên mình qua nền tảng công nghệ, từ đó có thể biết học viên đang yếu gì, cần thêm gì để hỗ trợ tốt hơn.',
  },
];

export const ListValueBrought = () => {
  return (
    <ValueBroughtList>
      {valueBroughts.map((v, index) => {
        return (
          <ValueBroughtListItem key={index}>
            <ValueBroughtListIcon>{v.icon}</ValueBroughtListIcon>
            <ValueBroughtListText>
              <ResponsiveTypography.Paragraph variant="SemiBold/24-32" color="grey180">
                {v.title}
              </ResponsiveTypography.Paragraph>
              <ValueBroughtListTextValue>
                <ResponsiveTypography.Paragraph variant="regular/16-24" color="gray140">
                  {v.content}
                </ResponsiveTypography.Paragraph>
              </ValueBroughtListTextValue>
            </ValueBroughtListText>
          </ValueBroughtListItem>
        );
      })}
    </ValueBroughtList>
  );
};
