import { ButtonGeneral } from '@doltech/ui/lib/figma/Button/ButtonGeneral/ButtonGeneral';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { IconSystemV2SpecificAi5 } from '@doltech/icons/SystemV2/IconSystemV2SpecificAi5';
import { Ref } from 'react';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';
import Image from 'next/image';

const ConnectTechnology = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-margin: 80px;
`;

const ConnectBorder = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colorsV2.grey40};
  border-radius: 20px;
  padding: 6px 12px;
  box-shadow: ${shadows.neutralShadowToBot3};
  .lp-connect-text {
    padding-left: 6px;
  }
`;
const TechnologyPlatform = styled.div`
  padding: 28px 0 60px;
  text-align: center;
  .hero-break-1 {
    display: none;
  }
  ${toScreen(959)} {
    padding: 28px 40px 60px;
    .hero-break-1,
    .hero-break-2 {
      display: none;
    }
  }
  ${toScreen(767)} {
    padding: 28px 0 60px;
    .hero-break-1,
    .hero-break-2 {
      display: block;
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 28px;
  p {
    max-width: 552px;
    align-self: stretch;
  }
`;
const TechnologyButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${flexGap([12, 12])}
  button {
    width: unset;
  }
  ${toScreen(767)} {
    flex-direction: column;
    ${flexGap(12, 'column')}
    button {
      width: 240px;
    }
  }
`;

const IntroImgContainer = styled.div`
  padding: 0 26px 80px;
  img {
    width: 100%;
    object-fit: cover;
    border: 4px solid ${colorsV2.rgbaGray01};
    border-radius: 12px;
    box-shadow: ${shadows.lmsSuperHero};
  }
  ${toScreen(1366)} {
    padding: 0 0 80px;
  }
  ${toScreen(767)} {
    padding: 0 12px 80px;
  }
`;

const images = {
  intro: imgProxyLoader({
    src: 'https://media.dolenglish.vn/directus-upload/74911cb3-6f40-42f3-9110-f707214f3e61.jpg',
    width: 1072,
  }),
};

interface IHero {
  handleScrollToAboutDol?: () => void;
}
export const Hero = React.forwardRef((props: IHero, ref: Ref<HTMLDivElement>) => {
  const { handleScrollToAboutDol } = props;

  return (
    <>
      <ConnectTechnology ref={ref}>
        <ConnectBorder>
          <IconSystemV2SpecificAi5 size={20} color={colorsV2.primary100} />
          <ResponsiveTypography.Paragraph
            className="lp-connect-text"
            variant="regular/14-20"
            color="grey180"
          >
            Kết hợp công nghệ vào giảng dạy
          </ResponsiveTypography.Paragraph>
        </ConnectBorder>
      </ConnectTechnology>
      <TechnologyPlatform>
        <ResponsiveTypography.Text
          variant={['bold/40-48', 'bold/48-56', 'bold/48-56']}
          breakpoints={[0, 768, 960]}
        >
          Nền tảng công
          <br className="hero-break-1"></br> nghệ độc quyền
        </ResponsiveTypography.Text>
        <br className="hero-break-2"></br>
        <ResponsiveTypography.Text
          variant={['bold/40-48', 'bold/48-56', 'bold/48-56']}
          breakpoints={[0, 768, 960]}
          color="primary100"
        >
          &nbsp;DOL superLMS
        </ResponsiveTypography.Text>
        <TextWrapper>
          <ResponsiveTypography.Paragraph variant="regular/16-28" color="gray140">
            DOL superLMS mang đến một trải nghiệm học toàn diện, giúp bạn tiếp cận việc học tiếng
            Anh một cách dễ dàng và tiết kiệm thời gian hơn.
          </ResponsiveTypography.Paragraph>
        </TextWrapper>
        <TechnologyButtonContainer>
          <a href="/login">
            <ButtonGeneral type="primary" size="large" text="Học viên đăng nhập" />
          </a>

          <ButtonGeneral
            type="secondary"
            size="large"
            text="Đôi lời về nền tảng"
            onClick={handleScrollToAboutDol}
          />
        </TechnologyButtonContainer>
      </TechnologyPlatform>
      <IntroImgContainer>
        <Image src={images.intro} width={1072} height={604} alt="intro_img" />
      </IntroImgContainer>
    </>
  );
});
