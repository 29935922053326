import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h5a1 1 0 100-2H4V4h12v5.5a1 1 0 102 0V4c0-1.1-.9-2-2-2zm-9 8.5h3a1 1 0 110 2H7a1 1 0 110-2zM12 7H7a1 1 0 100 2h5a1 1 0 100-2zm-5 7h1a1 1 0 110 2H7a1 1 0 110-2zm12.827-.56a1.999 1.999 0 00-2.829 0L13.438 17a2 2 0 00-.585 1.414V21a1 1 0 001 1h2.586c.53 0 1.038-.21 1.414-.586l3.56-3.56a2 2 0 000-2.829l-1.586-1.585zm-1.414 1.414L20 16.44 16.439 20h-1.586v-1.586l3.56-3.56z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export const IconSystemV2CourseMarkTestMarkTest2 = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
