import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { IconSystemV2BrandsLogoMessenger1 } from '@doltech/icons/SystemV2/IconSystemV2BrandsLogoMessenger1';
import { IconSystemV2OtherPhoneCallFilled } from '@doltech/icons/SystemV2/IconSystemV2OtherPhoneCallFilled';
import { environment } from '@doltech/core/lib/environment/environment';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { IconSystemV2CourseCenter } from '@doltech/icons/SystemV2/IconSystemV2CourseCenter';

const CTAContactGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${flexGap([20, 20])}
  ${toScreen(767)} {
    flex-direction: column;
    ${flexGap([0, 16])}
  }
`;
const CTAContactItem = styled.div`
  display: flex;
  padding: 8px 18px 8px 14px;
  align-items: center;
  ${flexGap(12)}
  border-radius: 16px;
  border: 1px solid ${colorsV2.white30};
  background: ${colorsV2.gradientWhite013_6To90};
  backdrop-filter: blur(10px);
  cursor: pointer;
  &:hover {
    border-color: ${colorsV2.white70};
    background: ${colorsV2.gradientWhite021_6To90};
  }
  ${toScreen(767)} {
    padding: 8px 12px;
    margin-right: 0;
  }
`;

const CTAContactItemIcon = styled.div`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  ${flexGap(8)}
  border-radius: 12px;
  background: ${colorsV2.white20};
`;
const CTAContactItemText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ctas = [
  {
    icon: <IconSystemV2BrandsLogoMessenger1 size={24} color={colorsV2.white100} />,
    title: 'Nhắn tin DOL qua Facebook',
    content: 'Nhắn tin messenger trực tiếp',
    link: 'https://m.me/dolenglish.ieltsdinhluc',
  },
  {
    icon: <IconSystemV2OtherPhoneCallFilled size={24} color={colorsV2.white100} />,
    title: 'Gọi điện liên hệ',
    content: '1800 96 96 39',
    link: 'tel:1800 96 96 39',
  },
  {
    icon: <IconSystemV2CourseCenter size={24} color={colorsV2.white100} />,
    title: 'Gặp trực tiếp tại cơ sở ',
    content: 'HCM, Hà Nội và Đà Nẵng',
    link: `${environment.REACT_APP_DOL_CORP_WEBSITE_HOST_NAME}/he-thong-co-so`,
  },
];

export const ListCTA = () => {
  return (
    <CTAContactGroup>
      {ctas.map((c, index) => {
        return (
          <a key={index} href={c.link} target="_blank" rel="noreferrer">
            <CTAContactItem>
              <CTAContactItemIcon>{c.icon}</CTAContactItemIcon>
              <CTAContactItemText>
                <ResponsiveTypography.Paragraph variant="SemiBold/16-24" color="white100">
                  {c.title}
                </ResponsiveTypography.Paragraph>
                <ResponsiveTypography.Paragraph variant="regular/16-24" color="white70">
                  {c.content}
                </ResponsiveTypography.Paragraph>
              </CTAContactItemText>
            </CTAContactItem>
          </a>
        );
      })}
    </CTAContactGroup>
  );
};
