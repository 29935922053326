import { ButtonGeneral } from '@doltech/ui/lib/figma/Button/ButtonGeneral/ButtonGeneral';
import { DolIeltsDinhLucLogo } from '@doltech/ui/lib/figma/DolLogo';
import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import * as React from 'react';
import styled from 'styled-components';
import { IMenuItem } from './types';
import { IconSystemV2EssentialClose } from '@doltech/icons/SystemV2/IconSystemV2EssentialClose';
import { IconSystemV2ContentMenuCenter } from '@doltech/icons/SystemV2/IconSystemV2ContentMenuCenter';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { shadows } from '@doltech/ui/lib/figma/shadows';
import { useFromScreens } from '@doltech/ui/lib/hooks/useDeviceQuery.hook';

const LmsStudentLandingPageHeader = styled.div`
  position: relative;
  background: ${colorsV2.white100};
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colorsV2.grey20};
  .logo-with-text {
    cursor: pointer;
  }
  ${toScreen(959)} {
    padding: 12px 24px;
  }
  ${toScreen(767)} {
    padding: 12px 16px;
  }
  .mobile-trial-support {
    ${toScreen(479)} {
      display: none;
    }
  }
  .header-small-menu {
    display: none;
    ${toScreen(959)} {
      display: flex;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${flexGap(32)}
  flex: 1 0 0;
  align-self: stretch;
  .lp-menu-text {
    span {
      cursor: pointer;
    }
  }
  ${toScreen(959)} {
    display: none;
  }
`;

const MenuItem = styled.div`
  display: flex;
  padding: 24px 0;
  align-items: flex-start;
  &:hover {
    color: ${colorsV2.red100};
    border-bottom: 1px solid ${colorsV2.red100};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${flexGap(12)}
`;

const CloseButton = styled.div`
  padding: 8px;
  display: flex;
  align-items: flex-start;
  ${flexGap(4)}
  border-radius: 40px;
  border: 2px solid ${colorsV2.grey20};
  cursor: pointer;
`;

const SmallMenuWrapper = styled.div`
  display: flex;
  width: 251px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 24px;
  top: 59px;
  border-radius: 12px;
  border: 1px solid ${colorsV2.grey40};
  background: ${colorsV2.white100};
  box-shadow: ${shadows.zIndex3};
  ${toScreen(767)} {
    top: 64px;
    border-radius: 0;
    width: 100%;
    right: 0;
  }
`;

const SmallMenuItem = styled.div`
  display: flex;
  padding: 12px 14px;
  align-items: center;
  ${flexGap(16)}
  align-self: stretch;
  .lp-menu-text {
    cursor: pointer;
    &:hover {
      color: ${colorsV2.red100};
      border-bottom: 1px solid ${colorsV2.red100};
    }
  }
`;
interface IProps {
  menuItems: IMenuItem[];
  handleClick?: (c: string) => void;
  handleScrollToTop?: () => void;
  haveTrialData?: boolean;
}

export const LandingPageHeader = (props: IProps) => {
  const { haveTrialData, menuItems, handleClick, handleScrollToTop } = props;
  const [isShowMenu, setIsShowMenu] = React.useState<boolean>(false);
  const [, from769] = useFromScreens([0, 769]);
  const btnSize = from769 ? 'large' : 'small';

  return (
    <LmsStudentLandingPageHeader>
      <DolIeltsDinhLucLogo onClick={handleScrollToTop} className="logo-with-text" />
      <Menu>
        {menuItems.map((i: IMenuItem) => {
          return (
            <MenuItem key={i.value}>
              <ResponsiveTypography.Paragraph
                className="lp-menu-text"
                variant="regular/16-24"
                color="grey180"
                onClick={() => handleClick(i.value)}
              >
                {i.label}
              </ResponsiveTypography.Paragraph>
            </MenuItem>
          );
        })}
      </Menu>
      <ButtonWrapper>
        {haveTrialData && (
          <a href="/free-trial/onboarding">
            <ButtonGeneral type="secondary" size={btnSize} text="Trải nghiệm thử" />
          </a>
        )}
        <a href="/login" className={haveTrialData &&'mobile-trial-support'}>
          <ButtonGeneral type="primary" size={btnSize} text="Đăng nhập" />
        </a>

        {isShowMenu ? (
          <CloseButton className="header-small-menu" onClick={() => setIsShowMenu((pre) => !pre)}>
            <IconSystemV2EssentialClose />
          </CloseButton>
        ) : (
          <CloseButton className="header-small-menu" onClick={() => setIsShowMenu((pre) => !pre)}>
            <IconSystemV2ContentMenuCenter />
          </CloseButton>
        )}
      </ButtonWrapper>
      {isShowMenu && (
        <SmallMenuWrapper className="header-small-menu">
          {menuItems.map((i: IMenuItem) => {
            return (
              <SmallMenuItem key={i.value}>
                <ResponsiveTypography.Paragraph
                  className="lp-menu-text"
                  variant="regular/16-24"
                  color="grey180"
                  onClick={() => handleClick(i.value)}
                >
                  {i.label}
                </ResponsiveTypography.Paragraph>
              </SmallMenuItem>
            );
          })}
        </SmallMenuWrapper>
      )}
    </LmsStudentLandingPageHeader>
  );
};
