import * as React from 'react';
import styled from 'styled-components';
import { IHelping } from './Helping';
import Moveable from 'react-moveable';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';

const images = {
  courseFollowing1: imgProxyLoader({
    src: 'https://media.dolenglish.vn/directus-upload/ac020ec5-825d-4428-95e1-6f1d4d169307.png',
    width: 1327,
  }),
  courseFollowing2:
    'https://gqefcpylonobj.vcdn.cloud/directus-upload/0d622f33-974e-478c-bb7a-a16b354ea986.png',
  courseFollowing3:
    'https://gqefcpylonobj.vcdn.cloud/directus-upload/ce745964-9a73-4652-898b-b5ecd1a9cff8.png',
  courseFollowingGrid: imgProxyLoader({
    src: 'https://media.dolenglish.vn/directus-upload/52b0b7b1-db2d-4524-bf57-ad0cc0384973.png',
    width: 1327,
  }),
  CourseFollowingVector:
    'https://gqefcpylonobj.vcdn.cloud/directus-upload/82bb621c-1dfe-44f1-bd41-8a128a728e70.png',
};

const HWWrapper = styled.div`
  .hw-1 {
    padding: 18px 24px 24px 0;
    background: url(${images.courseFollowingGrid}), ${colorsV2.gradientRed28To82};
    background-size: cover;
  }
  .hw-2 {
    padding: 24px;
    background: url(${images.CourseFollowingVector}), ${colorsV2.gradientRed28To82};
    background-size: cover;
  }
  .hw-3 {
    padding: 24px;
    background: url(${images.courseFollowingGrid}), ${colorsV2.gradientRed28To82};
    background-size: cover;
  }
`;

const HomeworkGroup = styled.div`
  margin-top: 32px;
  display: flex;
  height: 448px;
  flex-direction: column;
  align-items: flex-start;
  ${flexGap(8, 'column')}
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid ${colorsV2.rgbaGray02};
  overflow: scroll;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  :-webkit-scrollbar {
    display: none;
  }
  .moveable-control-box {
    --moveable-color: transparent;
  }
  .moveable-origin {
    display: none;
  }

  ${toScreen(1199)} {
    display: none;
  }
`;

interface Iprops {
  helpings: IHelping[];
}
export const Homework = (props: Iprops) => {
  const { helpings } = props;
  const targetRef = React.useRef<HTMLImageElement>(null);

  const getContentImgUrl = () => {
    const activedItem = helpings.find((h) => h.actived === true);
    switch (activedItem?.id) {
      case 1:
        return images.courseFollowing1;
      case 2:
        return images.courseFollowing2;
      case 3:
        return images.courseFollowing3;
      default:
        return images.courseFollowing1;
    }
  };

  const getClassName = () => {
    const activedItem = helpings.find((h) => h.actived === true);
    switch (activedItem?.id) {
      case 1:
        return 'hw-1';
      case 2:
        return 'hw-2';
      case 3:
        return 'hw-3';
      default:
        return 'hw-1';
    }
  };

  return (
    <HWWrapper>
      <HomeworkGroup className={getClassName()}>
        <img
          loading="lazy"
          ref={targetRef}
          alt="intro_img"
          src={getContentImgUrl()}
          width={1327}
          height={544}
        />
        <Moveable
          target={targetRef}
          draggable
          onDrag={({ target, transform }) => {
            target!.style.transform = transform;
          }}
        />
      </HomeworkGroup>
    </HWWrapper>
  );
};
