import * as React from 'react';
import styled from 'styled-components';
import { compose, space, color, layout, typography, SpaceProps, ColorProps, LayoutProps, TypographyProps } from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement> & {
  title?: any;
}>(({
  title,
  ...props
}, svgRef) => {
  const [titleId] = React.useState(() => title ? nanoid() : undefined);
  return <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} aria-labelledby={titleId} {...props}>{title ? <title id={titleId}>{title}</title> : null}<path fill="url(#IconSystemV2ColoredCourseColorHeart_svg__IconSystemV2ColoredCourseColorHeart_svg__paint0_linear_8199_90404)" d="M12 4.81A6.5 6.5 0 001 9.5c0 6.368 6.97 9.885 9.814 11.055a3.096 3.096 0 002.372 0C16.03 19.385 23 15.868 23 9.5a6.5 6.5 0 00-11-4.69z" /><path fill="url(#IconSystemV2ColoredCourseColorHeart_svg__IconSystemV2ColoredCourseColorHeart_svg__paint1_linear_8199_90404)" fillRule="evenodd" d="M12 8.86l-1.077-.957A3.633 3.633 0 008.5 7C6.567 7 5 8.452 5 10.243c0 2.158 1.6 3.853 3.599 5.116a17.416 17.416 0 002.69 1.369c.278.113.52.204.711.272.192-.068.433-.159.711-.272a17.418 17.418 0 002.69-1.37C17.4 14.097 19 12.402 19 10.244 19 8.453 17.433 7 15.5 7a3.63 3.63 0 00-2.423.903L12 8.86z" clipRule="evenodd" /><defs><linearGradient id="IconSystemV2ColoredCourseColorHeart_svg__IconSystemV2ColoredCourseColorHeart_svg__paint0_linear_8199_90404" x1={20.08} x2={11.71} y1={19.24} y2={1.77} gradientUnits="userSpaceOnUse"><stop stopColor="#D14242" /><stop offset={1} stopColor="#F18989" /></linearGradient><linearGradient id="IconSystemV2ColoredCourseColorHeart_svg__IconSystemV2ColoredCourseColorHeart_svg__paint1_linear_8199_90404" x1={6.8} x2={12.54} y1={8} y2={20.59} gradientUnits="userSpaceOnUse"><stop stopColor="#fff" /><stop offset={1} stopColor="#fff" stopOpacity={0} /></linearGradient></defs></svg>;
});
export const IconSystemV2ColoredCourseColorHeart = styled(SvgComponent)<IconProps>({
  flex: 'none',
  verticalAlign: 'middle'
}, compose(space, color, layout, typography));