import { LmsStudentLandingPage } from '@doltech/domains/lms-student/lib/landing-page/LmsStudentLandingPage';
import * as React from 'react';

type HomePageProps = React.HTMLAttributes<HTMLDivElement>;

const HomePage = (_props: HomePageProps) => {
  return <LmsStudentLandingPage />;
};

export default HomePage;
