import * as React from 'react';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  layout,
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  TypographyProps,
} from 'styled-system';
import { nanoid } from 'nanoid';
export type IconProps = SpaceProps & ColorProps & LayoutProps & TypographyProps;
const SvgComponent = React.forwardRef<
  SVGSVGElement,
  React.SVGProps<SVGSVGElement> & {
    title?: any;
  }
>(({ title, ...props }, svgRef) => {
  const [titleId] = React.useState(() => (title ? nanoid() : undefined));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.968 3.044c0-.06.05-.108.11-.108h12.844c.06 0 .11.048.11.108v2.972a.984.984 0 001.968 0V3.044C19 1.915 18.07 1 16.922 1H4.078C2.93 1 2 1.915 2 3.044V20a1 1 0 000 2h20a1 1 0 000-2h-1.536v-2.243a3.43 3.43 0 002.528-3.309v-2.02a3.43 3.43 0 00-6.86 0v2.02a3.43 3.43 0 002.516 3.306V20H12v-1.053c0-.523-.448-.947-1-.947s-1 .424-1 .947V20H3.968V3.044zM7.038 5C6.465 5 6 5.448 6 6s.465 1 1.038 1h6.924C14.535 7 15 6.552 15 6s-.465-1-1.038-1H7.038zm10.91 7.429a1.614 1.614 0 013.229 0v2.02a1.614 1.614 0 01-3.229 0v-2.02zM6.923 13c-.51 0-.923.448-.923 1s.413 1 .923 1h6.154c.51 0 .923-.448.923-1s-.413-1-.923-1H6.923zM6 10c0-.552.413-1 .923-1h4.154c.51 0 .923.448.923 1s-.413 1-.923 1H6.923C6.413 11 6 10.552 6 10z"
        clipRule="evenodd"
      />
    </svg>
  );
});
export const IconSystemV2CourseCenter = styled(SvgComponent)<IconProps>(
  {
    flex: 'none',
    verticalAlign: 'middle',
  },
  compose(space, color, layout, typography)
);
