import * as React from 'react';
import styled from 'styled-components';
import { FooterTopFrameLeft } from './FooterTopFrameLeft';
import { FooterTopFrameRight } from './FooterTopFrameRight';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';

const FooterTopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 60px;
  padding-bottom: 72px;
  ${toScreen(767)} {
    grid-template-columns: 1fr;
  }
`;

export const FooterTop = () => {
  return (
    <FooterTopWrapper>
      <FooterTopFrameLeft />
      <FooterTopFrameRight />
    </FooterTopWrapper>
  );
};
