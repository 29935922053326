import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import * as React from 'react';
import styled from 'styled-components';
import { ListCTA } from './ListCTA';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import { imgProxyLoader } from '@doltech/utils/lib/image-loader';

const CTASection = styled.div`
  padding: 120px 0 40px;
  ${toScreen(767)} {
    padding: 80px 0 32px;
  }
`;
const CTAContainer = styled.div`
  display: flex;
  padding: 52px 60px 60px;
  flex-direction: column;
  ${flexGap(40, 'column')}
  align-self: stretch;
  border-radius: 32px;
  position: relative;
  background-image: ${colorsV2.gradientRed};
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .bg-cover {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  ${toScreen(767)} {
    ${flexGap(24, 'column')}
    padding: 32px 20px;
  }
`;
const CTAHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  z-index: 2;
`;

export const CTA = () => {
  return (
    <CTASection>
      <CTAContainer>
        <img
          loading="lazy"
          className="bg-cover"
          src={imgProxyLoader({
            src: 'https://gqefcpylonobj.vcdn.cloud/directus-upload/e161a48b-e503-4913-a368-0975ae9623c8.png',
            width: 1132,
          })}
          alt="bg-cover"
          width={1132}
          height={481}
        />
        <CTAHeading>
          <ResponsiveTypography.Paragraph
            variant={['Bold/24-32', 'Bold/32-40', 'Bold/32-40']}
            breakpoints={[0, 768, 960]}
            color="white100"
          >
            Đăng ký học để được sử
          </ResponsiveTypography.Paragraph>
          <ResponsiveTypography.Paragraph
            variant={['Bold/24-32', 'Bold/32-40', 'Bold/32-40']}
            breakpoints={[0, 768, 960]}
            color="white100"
          >
            dụng superLMS miễn phí
          </ResponsiveTypography.Paragraph>
        </CTAHeading>
        <ListCTA />
      </CTAContainer>
    </CTASection>
  );
};
