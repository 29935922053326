import { ResponsiveTypography } from '@doltech/ui/lib/figma/Typography/v3/ResponsiveTypography';
import { colorsV2 } from '@doltech/ui/lib/figma/colors-v2';
import * as React from 'react';
import styled from 'styled-components';
import { FooterTopIntro } from './FooterTopIntro';
import { toScreen } from '@doltech/core/lib/responsive/responsive.util';
import { flexGap } from '@doltech/utils/lib/css-style';

const FooterTopFrameRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(36, 'column')}
`;
const FooterTopCourses = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(16, 'column')}
  align-self: stretch;
`;
const FooterTopList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  ${toScreen(1199)} {
    grid-template-columns: 1fr;
  }
`;
const FooterTopListItem = styled.a`
  display: flex;
  padding: 12px 20px 12px 18px;
  ${flexGap(12)}
  border-radius: 16px;
  background: ${colorsV2.grey160};
  align-self: stretch;
  &:hover {
    cursor: pointer;
    background: ${colorsV2.grey120};
  }
  ${toScreen(1199)} {
    align-self: stretch;
  }
`;
const FooterTopListItemText = styled.div`
  display: flex;
  flex-direction: column;
  ${flexGap(4, 'column')}
  flex: 1 0 0;
`;

const courses = [
  {
    title: 'IELTS',
    content: 'Giúp đạt target IELTS dễ dàng và nâng cao năng lực tư duy ngôn ngữ.',
    link: 'https://www.dolenglish.vn/khoa-hoc-ielts',
  },
  {
    title: 'SAT',
    content: 'Đạt target SAT nhanh chóng và chuẩn bị kỹ năng cho học đại học tại Mỹ.',
    link: 'https://www.dolenglish.vn/sat',
  },
  {
    title: 'TOEIC',
    content: 'Được thiết kế để nâng cao khả năng tiếng Anh trong môi trường làm việc.',
    link: 'https://www.dolenglish.vn/toeic',
  },
  {
    title: 'TA Giao tiếp',
    content: 'Giúp học viên tự tin giao tiếp tiếng Anh trong mọi tình huống.',
    link: 'https://www.dolenglish.vn/tieng-anh-giao-tiep',
  },
];

export const FooterTopFrameRight = () => {
  return (
    <FooterTopFrameRightWrapper>
      <FooterTopCourses>
        <ResponsiveTypography.Paragraph variant="regular/16-24" color="grey100">
          KHÓA HỌC TẠI DOL - HƠN 40 KHÓA HỌC
        </ResponsiveTypography.Paragraph>
        <FooterTopList>
          {courses.map((c, index) => {
            return (
              <FooterTopListItem key={index} href={c.link} target="_blank" rel="noreferrer">
                <FooterTopListItemText>
                  <ResponsiveTypography.Paragraph variant="SemiBold/18-24" color="white100">
                    {c.title}
                  </ResponsiveTypography.Paragraph>
                  <ResponsiveTypography.Paragraph variant="regular/14-20" color="grey60">
                    {c.content}
                  </ResponsiveTypography.Paragraph>
                </FooterTopListItemText>
              </FooterTopListItem>
            );
          })}
        </FooterTopList>
      </FooterTopCourses>
      <FooterTopIntro />
    </FooterTopFrameRightWrapper>
  );
};
